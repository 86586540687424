import styled, { css } from "styled-components"

export const ConfirmContainer = styled.div`
    padding: 12px 0px;
    height: 100%;
`
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`
export const DefaultButton = css`
    padding: 12px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 14px;
    flex: 0 0 calc(50% - 4px);
`
export const CancelButton = styled.button`
    ${DefaultButton}
    color: ${({ theme, }) => theme.palette.common.dark};
    background-color: ${({ theme, }) => theme.primary.contrastText};
    border: 1px solid #9A9A9A;
`
export const ConfirmButton = styled.button`
    ${DefaultButton}
    color: ${({ theme, }) => theme.primary.contrastText};
    background-color: ${({ theme, }) => theme.primary.main};
    border: 1px solid transparent;
`
export const QuestionText = styled.p`
    font-size: 14px;
    color: ${({ theme, }) => theme.palette.common.dark};
`
export const WarningImg = styled.img`
    align-self: flex-start;
`
export const ContainerAlert = styled.div`
    display:flex;
    background-color: ${({ theme, }) => theme.error.background};
    border-radius: 8px;
    margin: 24px;
    padding: 8px;
`
export const AlertMessage = styled.p`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ theme, }) => theme.error.main};
  font-weight: 400;
`
export const ToastMsg = styled.p`
  font-size: 18px;
  color: ${({ theme, }) => theme.primary.contrastText};
  padding: 0px 15px;
`