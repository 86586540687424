import React from "react"
import { useRecoilValue } from "recoil"
import { orderAtom } from "state"
import Layout from "components/layouts/Layout"
import { GroupBy } from "gdu-vq-common/src/helpers/groupBy"
import OrderPreparation from "./orderPreparation/OrderPreparation"
import OrderByState from "./orderList/OrderByState"
import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import { OperatorOrderState } from "types"
import { ContentWrapper } from "components/displays/Elements"
import {
    NumberOfOrders,
    OrderDetailListWrapper,
    OrderListWrapper,
    OrderSection,
    OrderSectionHeader,
    OrdersWrapper,
    Title,
    OrdersSectionWrapper,
} from "./styles/OrderStyles"

const StateTransform = {
    [OrderState.onHold]: OperatorOrderState.onHold,
    [OrderState.canceled]: OperatorOrderState.closed,
    [OrderState.inProgress]: OperatorOrderState.active,
    [OrderState.completed]: OperatorOrderState.closed,
    [OrderState.canceledInProgress]: OperatorOrderState.active,
}

const labels = {
    [OperatorOrderState.onHold]: "en espera",
    [OperatorOrderState.closed]: "Cerrado",
    [OperatorOrderState.active]: "En Preparación",
}

const NUMBER_MAX_ORDERS = 3

const Order = () => {

    const order = useRecoilValue(orderAtom)

    const orderList = GroupBy(order!, ({ state, }) => StateTransform[state])

    const numberOfOnHoldOrders = orderList.ON_HOLD ? orderList.ON_HOLD.length : 0
    const numberOfActiveOrders = orderList.ACTIVE ? orderList.ACTIVE.length : 0

    const renderOnHoldOrders = () => (<OrderSection>
        <OrderSectionHeader>
            <div style={{ display: "flex", }}>
                <Title> {labels[OperatorOrderState.onHold]} </Title>
            </div>
            <NumberOfOrders>
                {numberOfOnHoldOrders}
            </NumberOfOrders>
        </OrderSectionHeader>
        <OrdersSectionWrapper>
            {(numberOfOnHoldOrders > 0)
                ? <>
                    <OrderByState
                        orders={numberOfOnHoldOrders > NUMBER_MAX_ORDERS
                            ? orderList.ON_HOLD.slice(0, NUMBER_MAX_ORDERS)
                            : orderList.ON_HOLD
                        }
                    />
                </>
                : <ContentWrapper>
                    No hay pedidos en espera. Al ordenarse nuevos aparecerán aquí.
                </ContentWrapper>}
        </OrdersSectionWrapper>

    </OrderSection>)

    const renderActiveOrders = () => (<OrderSection>
        <OrderSectionHeader>
            <Title> {labels[OperatorOrderState.active]} </Title>
            <NumberOfOrders>
                {numberOfActiveOrders}
            </NumberOfOrders>
        </OrderSectionHeader>
        <OrdersSectionWrapper>
            {numberOfActiveOrders > 0
                ? <OrderByState orders={orderList.ACTIVE} />
                : <ContentWrapper >
                    Cambia el estado de los pedidos para comenzar a prepararlos.
                </ContentWrapper>}
        </OrdersSectionWrapper>
    </OrderSection>)

    return (
        <Layout withHeader>
            <OrdersWrapper>
                <OrderListWrapper>
                    {renderOnHoldOrders()}
                    {renderActiveOrders()}
                </OrderListWrapper>
                <OrderDetailListWrapper>
                    <OrderPreparation orders={orderList.ACTIVE} />
                </OrderDetailListWrapper>
            </OrdersWrapper>
        </Layout>
    )
}

export default Order
