import { Link } from "react-router-dom"
import styled from "styled-components"


export const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  background-color: ${({ theme, }) => theme.palette.common.grey1};
  color:${({ theme, }) => theme.palette.common.white};
  padding: 16px 0px;
  border-radius: 24px;
`

export const ContentWrapper = styled.div`
  padding: 12px 16px;
  background-color: transparent;
  font-size: 14px;
  font-weight:400;
  min-height:60px;
  max-height:60px;
  display: flex;
  align-items: center;
  color: ${({ theme, }) => theme.palette.common.dark};
`
export const IconWrapper = styled.div`
  margin-right: 6;
  background-color: ${({ theme, }) => theme.error.main};
  color: ${({ theme, }) => theme.error.contrastText};
  border-radius: 2px;
  font-size: 8px;
  height: 12px;
  width: 12px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
`

export const StyledNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 16px;
`


export const StyledButton = styled.button`
  display: block;
  width: 100%;
  background-color: ${({ theme, }) => theme.palette.common.grey1};
  color:${({ theme, }) => theme.palette.common.white};
  padding: 16px 0px;
  border-radius: 24px;
`