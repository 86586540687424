import styled from "styled-components"

export const ContainerConfirmModal = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    padding: 24px 0px;
`

export const ConfirmMsg = styled.p`
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.3px;
    margin-bottom: 36px;
`

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`


const confirmDefaultButton: React.CSSProperties = {
    width: "calc(50% - 2px)",
    fontSize: "12px",
    padding: "8px 4px",
    borderRadius: "8px",
    lineHeight: "140%",
    letterSpacing: "-0.3px",
}

export const ConfirmDeleteButton = styled.button`
    ${{ ...confirmDefaultButton, }}
    background-color: ${({ theme, }) => theme.primary.main};
    color: rgb(250, 250, 250);
    border: 1px solid transparent;   
`

export const ConfirmBackButton = styled.button`
    ${{ ...confirmDefaultButton, }}
    color: rgb(51, 51, 51);
    border: 1px solid rgb(51, 51, 51);
`