import React, { ReactChild } from "react"
import Modal from "react-modal"
import { useNavigate } from "react-router-dom"

Modal.setAppElement("#root")

const ModalComp = (props: {
    children: ReactChild | ReactChild[],
    closeModal?: () => void,
    modalIsOpen: boolean,
    modalStyle?: Modal.Styles | undefined,
}) => {
    const navigate = useNavigate()
    const onDismiss = () => {
        if (props.closeModal) {
            props.closeModal!()
        } else {
            navigate(-1)
        }
    }

    return < Modal
        isOpen={props.modalIsOpen}
        onRequestClose={onDismiss}
        style={props.modalStyle}
    >
        {props.children}
    </Modal >
}

export default ModalComp