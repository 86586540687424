/* eslint-disable max-len */
import React, { useEffect, } from "react"
import { Navigate, Route, } from "react-router-dom"
import { pathsDefinition, useBrand, usePathsNavigator, } from "util/paths"
import Orders from "pages/order/Orders"
import { OrderObserver } from "pages/order/OrderObserver"
import { SectionStore } from "api/ApiClient"
import { useRecoilState, } from "recoil"
import { storeAtom } from "state"
import { useRequestThrowError } from "hooks/useRequestThrowError"
import { BrandStoreInfo } from "gdu-vq-common/src/api/interfaces/operator/models/OperatorStore"
import { ThemeProvider } from "styled-components"
import { switchBrands } from "util/SwitchThemeBrands"
import LoadingWrapper from "components/displays/LoadingWrapper"
import ToastProvider from "components/providers/ToastProvider"
import RootRoutes from "components/utils/GenerateAbsolutePath"

const Protected = () => {
        const brand = useBrand()
        const paths = usePathsNavigator()
        const [store, setStoreAtom,] = useRecoilState(storeAtom)

        const response = useRequestThrowError<BrandStoreInfo>({
                key: "getStore",
                func: () => SectionStore.getStore("POST", { brand, }),
        })
        useEffect(() => {
                if (!!response.data) {
                        setStoreAtom(response.data)
                }
        }, [response.data,])

        if (response.loading || !store) { return <LoadingWrapper /> }

        return (<ThemeProvider theme={switchBrands(brand)}>
                <ToastProvider>
                        <OrderObserver>
                                <RootRoutes>
                                        <Route path={pathsDefinition.orders} element={<Orders />} />
                                        <Route path={pathsDefinition.notFound} element={<Navigate to={paths("orders")} />} />
                                </RootRoutes>
                        </OrderObserver>
                </ToastProvider>
        </ThemeProvider>)
}

export default Protected
