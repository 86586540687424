import React from "react"
import { Wrapper, Content, SubTitle, Title, Icon } from "./styles/ErrorModalStyles"
import error from "assets/icons/error.svg"
import useSession from "hooks/useSession"

const ErrorModal = () => {
  const { isLogged, } = useSession()

  const handleOnClick = () => (window.location.href = window.location.pathname)

  const contentRender = () => {
    if (isLogged) {
      return <Content onClick={handleOnClick} >
        <Icon src={error} />
        <Title>¡Algo salió mal!</Title>
        <SubTitle>Por favor, intenta nuevamente.</SubTitle>
      </Content>
    } else {
      return <Content>
        <Icon src={error} />
        <Title>¡Algo salió mal!</Title>
        <SubTitle>Vuelva a solicitar la direccion URL</SubTitle>
      </Content>
    }
  }

  return (
    <Wrapper>
      {contentRender()}
    </Wrapper>
  )
}

export default ErrorModal