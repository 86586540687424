
import useSession from "hooks/useSession"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { storeAtom } from "state"
import { useBrand } from "util/paths"
import {
    ContainerConfirmModal,
    ConfirmMsg,
    ButtonsContainer,
    ConfirmBackButton,
    ConfirmDeleteButton,
} from "./style/LogoutStyles"

type PropsType = {
    cancel: () => void,
}

const LogOut = ({ cancel, }: PropsType) => {
    const { finishSession, } = useSession()
    const store = useRecoilValue(storeAtom)!
    const brand = useBrand()
    const navigate = useNavigate()

    const handleCloseSession = () => {
        finishSession()
        navigate(`/${brand}`)
    }

    return <ContainerConfirmModal>
        <ConfirmMsg>
            ¿Desea cerrar sesión en la sección <b>{store.sectionInfo.sectionType}</b>?
        </ConfirmMsg>
        <ButtonsContainer>
            <ConfirmBackButton onClick={cancel}>
                Volver atrás
            </ConfirmBackButton>
            <ConfirmDeleteButton
                onClick={handleCloseSession}
            >
                Cerrar sesión
            </ConfirmDeleteButton>
        </ButtonsContainer>
    </ContainerConfirmModal>
}

export default LogOut