import styled from "styled-components"

export const Wrapper = styled.div`
  display:flex;
  justify-content:space-between;
  width: 100%;
`

export const Content = styled.div`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Title = styled.div`
  font-size:12px;
  text-transform: uppercase;
`

export const OrderId = styled.p`
  font-size:22px;
  font-weight:600;
  text-transform: uppercase;
`
export const Time = styled.span`
  font-size:14px;
  padding:6px 12px;
  background:${({ theme, }) => theme.palette.common.dark}; ;
  border-radius:8px;
  font-weight: 600;
  color:${({ theme, }) => theme.primary.contrastText};
  background-color: ${({theme, }) => theme.palette.common.dark};
 `
export const TimeWrapper = styled.div`
  display:flex;
  flex-direction:column;
  font-size: 10px;
  align-items:center;
  font-weight:500;
  color: ${({ theme, }) => theme.primary.contrastText}
  `