import React from "react"
import { Img, Wrapper } from "./styles/InlineNotificationStyles"
import warning from "assets/icons/warning.svg"
type PropsType = {
  children: React.ReactNode,
};

const InlineNotification = ({ children, }: PropsType) => {

  return <Wrapper>
    <Img src={warning} alt="Icon" />
    {children}
  </Wrapper>

}

export default InlineNotification