/* eslint-disable max-lines-per-function */
import React, { useEffect } from "react"
import { Modals } from "styles/ModalStyle"
import ModalComp from "components/displays/ModalComp"
import { OperatorOrder } from "gdu-vq-common/src/api/interfaces/operator/models/OperatorOrder"
import {
    ConfirmContainer,
    ConfirmationText,
    OrderDetailContainer,
    ButtonContainer,
    CancelButton,
    ConfirmButton,
    OrderConfirmProductsContainer,
    TitleWrapper
} from "../styles/ConfirmCompleteModalStyle"
import OrderPreparationDetail from "./OrderPreparationDetail"
import PrintableContent from "./PrintableContent"
import {
    ContentWrapper,
    Title,
    Subtitle
} from "pages/order/orderList/styles/OrderStyles"
import { OrderDetailWrapper } from "../OrderPreparationStyle/OrderPreparationListStyle"


const ConfirmModal = (props: {
    openModal: boolean,
    orderPrepare: OperatorOrder,
    onClose: () => void,
    onDone: () => void,
    missingProducts: string[],
    toggleProductAvailability: (id: string,) => void,
}) => {
    const {
        openModal, orderPrepare, onClose, onDone, missingProducts, toggleProductAvailability,
    } = props
    const [printing, setPrinting,] = React.useState(false)
    const style = Modals.confirm
    const componentRef = React.useRef(null)
    const handleAfterPrint = () => {
        onClose()
        onDone()
        setPrinting(false)
    }

    const handlePrint = () => {
        const myWindow = window.open("", "")
        if (myWindow && componentRef.current) {
            myWindow.document.body.appendChild(componentRef.current)
            myWindow.document.head.innerHTML = `<style >
                
            @page {
            size: 10cm 8cm;
            }
        </style>
        `
            myWindow.focus()
            myWindow.print()
            setTimeout(() => {
                myWindow.close()
                handleAfterPrint()
            }, 1000)
        }
    }

    style!.content!.minWidth = 400
    useEffect(() => {
        if (printing) {
            handlePrint()
        }
    }, [printing,])

    return (<ModalComp
        modalIsOpen={openModal}
        modalStyle={style}
        closeModal={onClose}
    >
        <ConfirmContainer>
            <ConfirmationText>
                Confirmar pedido
            </ConfirmationText>
            <OrderDetailWrapper>
                <OrderDetailContainer>
                    <ContentWrapper>
                        <TitleWrapper>
                            <Subtitle>Código identificador</Subtitle>
                            <Title>{orderPrepare.alternativeId}</Title>
                        </TitleWrapper>
                    </ContentWrapper>
                </OrderDetailContainer>
            </OrderDetailWrapper>
            <OrderConfirmProductsContainer>
                {orderPrepare.orderLine.map(ol =>
                    <OrderPreparationDetail
                        key={ol.productId}
                        orderLine={ol}
                        missing={missingProducts.includes(ol.productId)}
                        onClick={() => { toggleProductAvailability(ol.productId) }}
                        showComment={false}
                    />
                )}
            </OrderConfirmProductsContainer>
            <ButtonContainer>
                <CancelButton
                    onClick={onClose}
                >
                    Continuar preparando
                </CancelButton>
                <ConfirmButton
                    onClick={() => {
                        setPrinting(true)
                    }}
                >
                    Confirmar e Imprimir
                </ConfirmButton>
            </ButtonContainer>
        </ConfirmContainer>
        {printing
            ? <PrintableContent
                ref={componentRef}
                clientName={orderPrepare.clientName}
                orderId={orderPrepare.alternativeId} /> : <></>}
    </ModalComp>
    )
}

export default ConfirmModal