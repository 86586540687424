import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  width:100%;
  height:100%;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width:257px;
  height:240px;
  background-color: white;
  border: 1px solid #00000050;
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  border-radius:24px;
`

export const Title = styled.p`
  font-size:22px;
  font-weight:600;
  margin-top:20px;
`
export const SubTitle = styled.p`
  font-size:14px;
  font-weight:400;
  margin-top:4px;
`