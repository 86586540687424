/* eslint-disable max-lines-per-function */
import React, { useState } from "react"
import Layout from "components/layouts/Layout"
import { InputField } from "components/fields/inputs/Input"
import {
    SubmitHandler,
    useImmerFormYup,
} from "immer-form/Validators/YupValidator"
import useSession from "hooks/useSession"
import { getDefaultValues, LoginForm, useFormValidation } from "./helpers"
import { useLocation, useNavigate, } from "react-router-dom"
import { SessionClient } from "api/ApiClient"
import { useBrand, usePathsNavigator } from "util/paths"
import { imgByBrand } from "components/utils/brandsImg/brandsImage"
import {
    LogoContainer,
    LogoBrand,
    Container,
    TitleLogin,
    SpanTitle,
    Form,
    Label,
    ButtonLogin,
    ErrorContainer,
    ErrorMsg,
} from "./style/LoginStyle"
import { ThemeProvider } from "styled-components"
import { switchBrands } from "util/SwitchThemeBrands"

const Login = () => {
    const { handleInitSession, } = useSession()
    const navigate = useNavigate()
    const brand = useBrand()
    const { state, } = useLocation()
    const redirectPath: string | null = state
    const [errorValidation, setErrorsValidation,] = useState(false)
    const [disable, setDisable,] = useState(false)
    const pathsNavigator = usePathsNavigator()

    const {
        token,
        handleSubmit,
        formState: {
            submittable,
        },
    } = useImmerFormYup<LoginForm>({
        defaults: getDefaultValues(),
        validator: useFormValidation(),
    })


    const onSubmit: SubmitHandler<LoginForm> = async ({ user, psw, }) => {
        const response = await SessionClient.login("POST", { username: user, password: psw, })
        if (response.ok) {
            setDisable(true)
            handleInitSession({
                userName: response.content.user.id,
                token: response.content.token,
            })

            if (redirectPath) {
                navigate(redirectPath, { replace: true, })
            } else {
                navigate(pathsNavigator("orders"), { replace: true, })
            }
            setDisable(false)
        } else if (response.errorsValidation) {
            setErrorsValidation(true)
            setDisable(false)
        }
    }
    return (<ThemeProvider theme={switchBrands(brand)}>
        <Layout>
            <Container>
                <LogoContainer>
                    <LogoBrand src={imgByBrand[brand].brandImg} />
                </LogoContainer>
                <TitleLogin>
                    ¡Bienvenido/a a
                    <SpanTitle> filas virtuales</SpanTitle>!
                </TitleLogin>

                <Form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Label
                        onChange={() => setErrorsValidation(false)}
                    >
                        <InputField
                            disabled={disable}
                            type="text"
                            token={token}
                            attr="user"
                            placeholder="Usuario"
                        />
                        <InputField
                            disabled={disable}
                            type="password"
                            token={token}
                            attr="psw"
                            placeholder="Contraseña"
                        />
                    </Label>
                    <ButtonLogin
                        disabled={!submittable}
                        type="submit"
                    >
                        Acceder
                    </ButtonLogin>
                    <ErrorContainer>
                        {errorValidation && <ErrorMsg>
                            Usuario o contraseña incorrectos
                        </ErrorMsg>}
                    </ErrorContainer>
                </Form>
            </Container>
        </Layout>
    </ThemeProvider>)
}

export default Login
