import { useMemo, } from "react"
import { Response } from "gdu-vq-common/src/api/interfaces/shared/ResponseTypes"
import { useRequest } from "./useRequest"

type Props<T> = {
    key: string,
    func: () => Promise<Response<T>>,
}
export const useRequestThrowError = <T>(props: Props<T>) => {
    const response = useRequest<T>({
        key: props.key,
        func: props.func,
    })

    return useMemo(() => {
        if (response.error) {
            throw new Error("Ha ocurrido un error.")
        } else {
            return response
        }
    }, [response,])
}

