import { Styles } from "react-modal"

const screen: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "hsl(0deg 0% 38% / 0.7)",
    },
    content: {
        position: "absolute",
        top: "70px",
        left: "0",
        right: "0",
        bottom: "0",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderTopRightRadius: "25px",
        borderTopLeftRadius: "25px",
        outline: "none",
        padding: "20px",

    },
}

const confirm: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.16)",
        zIndex: 2,
    },
    content: {
        maxBlockSize: "max-content",
        textAlign: "center",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        minHeight: "207px",
        maxWidth: "530px",
        height: "fit-content",
        WebkitOverflowScrolling: "touch",
        borderRadius: "25px",
        outline: "none",
        padding: "24px",
        inset: "50% calc(30%) 40px",
    },
}

const print: Styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.16)",
    },
    content: {
        maxBlockSize: "max-content",
        textAlign: "center",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        height: "fit-content",
        WebkitOverflowScrolling: "touch",
        borderRadius: "25px",
        outline: "none",
        width: "fit-content",
        minHeight: "157px",
        padding: "24px",
        inset: "50% calc(30%) 40px",
    },
}


export const Modals = {
    screen,
    confirm,
    print,
}