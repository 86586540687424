import startedBrand from "assets/icons/disco/startedBrand.svg"
import locationRed from "assets/icons/disco/locationRed.svg"
import locationBlack from "assets/icons/locationBlack.svg"
import discoLogo from "assets/icons/disco/discoLogo.png"
import devotoLogo from "assets/icons/devoto/devotoLogo.png"
import geantLogo from "assets/icons/geant/geantLogo.png"

export const discoImg = {
    locationRed,
    brandImg: discoLogo,
    startedBrand,
    locationBlack,
}

export const devotoImg = {
    locationRed,
    brandImg: devotoLogo,
    startedBrand,
    locationBlack,
}

export const geantImg = {
    locationRed,
    brandImg: geantLogo,
    startedBrand,
    locationBlack,
}