import styled from "styled-components"

export const orderPreparationListWidth = 465
export const orderPreparationListMargin = 30

type Props = {
  disable: boolean,
}

export const OrderContainer = styled.div<{ selected?: boolean, }>`
    background-color: ${({ theme, }) => theme.palette.common.white1};
    border-radius: 20px;
    height: 100%;
    min-width:${orderPreparationListWidth}px;
    margin-right: ${orderPreparationListMargin}px;
    padding: 1px;
    flex: 0 0 calc(30% - 4px);
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme, }) => theme.palette.common.grey2};

    ${({ selected, theme, }) => {
    if (selected) {
      return `
      & {
        border-width: 2px;
        padding: 0px;
        border-color: ${theme.secondary.main};
      }
      `
    }
    return ""
  }}
`

export const OrderDetailContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme, }) => theme.palette.common.grey2};
    border-radius:20px;
    background: ${({ theme, }) => theme.palette.common.white};
    box-shadow: 0px 23px 33px -24px rgba(0, 0, 0, 0.25);
`

export const OrderDetailWrapper = styled.div`
padding: 20px;
border-bottom: 1px solid ${({ theme, }) => theme.palette.common.grey2};
flex: 0;
`

export const ProductContainer = styled.div<Props>`
  padding: 20px;
  flex: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  opacity: ${({ disable, }) => (disable ? "0.4" : "1")};

  &::-webkit-scrollbar {
    display: none;
  }
`

export const OrderFinalizeButtonWrapper = styled.div`
  background-color: ${({ theme, }) => theme.palette.common.white1};
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  flex: 0;
`

export const OrderFinalizeButton = styled.button`
  color: ${({ theme, }) => theme.primary.contrastText};
  padding: 18px 0;
  line-height: 140%;
  border-radius: 12px;
  display: block;
  font-size:14px;
  width: 100%;
  background-color: ${({ theme, }) => theme.primary.main};
  font-weight: 500;
`

export const Wrapper = styled.div`
  height: 100%;
  opacity: 0.4;
`

export const LoaderWrapper = styled.div`
  width: 100%;
  align-items: center;
  padding: 25px;
`

export const LoaderContainer = styled.div`
  position: fixed; 
  top: 300px;
`


export const InlineNotificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`