import { devotoImg, discoImg, geantImg, } from "components/utils/brandsImg/disco/imgDisco"
import { Brands } from "gdu-vq-common/src/api/interfaces/shared/models/Brand"
import butcherShop from "assets/icons/sections/butcherShop.svg"
import bakery from "assets/icons/sections/bakery.svg"
import cheeseShop from "assets/icons/sections/cheeseShop.svg"
import delicatessen from "assets/icons/sections/delicatessen.svg"
import fishShop from "assets/icons/sections/fishShop.svg"
import defProductButcherShop from "assets/icons/sections/altProductButcherShop.png"
import defProductBakery from "assets/icons/sections/altProductBakery.png"
import defProductCheeseShop from "assets/icons/sections/altProductCheeseShop.svg"
import defProductDelicatessen from "assets/icons/sections/altProductDelicatessen.svg"
import defProductFishShop from "assets/icons/sections/altProductFishShop.png"
import { SectionType } from "gdu-vq-common/src/api/interfaces/shared/models/Section"

export const imgByBrand = {
    [Brands.disco]: discoImg,
    [Brands.devoto]: devotoImg,
    [Brands.geant]: geantImg,
} as const

export const sectionImg = {
    [SectionType.carniceria]: butcherShop,
    [SectionType.panaderia]: bakery,
    [SectionType.pescaderia]: fishShop,
    [SectionType.quesosFiambres]: cheeseShop,
    [SectionType.rotiseria]: delicatessen,
} as const

export const defaultProductImg = {
    [SectionType.carniceria]: defProductButcherShop,
    [SectionType.panaderia]: defProductBakery,
    [SectionType.pescaderia]: defProductFishShop,
    [SectionType.quesosFiambres]: defProductCheeseShop,
    [SectionType.rotiseria]: defProductDelicatessen,
} as const
