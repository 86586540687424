import styled, { css } from "styled-components"

const ConfirmContainer = styled.div`
    padding: 12px 0px;
    height: 100%;
`

const ConfirmationText = styled.p`
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
    color: #262626;
    margin-bottom: 16px;
`

const AlertText = styled.p`
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: ${({ theme, }) => theme.error.main};
    background-color: ${({ theme, }) => theme.error.background};
    padding: 4px;
    border-radius: 6px;
    text-transform: uppercase;
    ::before{
        content: "!";
        color: ${({ theme, }) => theme.error.contrastText};
        background-color: ${({ theme, }) => theme.error.main};
        padding: 1px 8px 2px 8px;
        border-radius: 4px;
        margin-right: 8px;
    }
`

const ContainerWrapper = styled.div`
    padding: 0px 24px;
    width: 100%;
`

const OrderDetailContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #EDEDED;
    margin: 45px 0px;
    padding: 14px 20px;
    border-radius: 14px;
`

const OrderContainer = styled.div`
    text-align: left;
    text-transform: uppercase;
`

const Idtext = styled.p`
    font-size: 22px;
    font-weight: 600;
    color:${({ theme, }) => theme.palette.common.dark};
`

const CodeText = styled.p`
    font-size: 9px;
    font-weight: 400;
    line-height: 130%;
    color:${({ theme, }) => theme.palette.common.dark};
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const DefaultButton = css`
    padding: 14px;
    font-size: 12px;
    font-weight: 300;
    border-radius: 14px;
    flex: 0 0 calc(50% - 4px);
`

const CancelButton = styled.button`
    ${DefaultButton}
    color: ${({ theme, }) => theme.palette.common.dark};
    background-color: ${({ theme, }) => theme.primary.contrastText};
    border: 1px solid #9A9A9A;
`

const ConfirmButton = styled.button`
    ${DefaultButton}
    color: ${({ theme, }) => theme.primary.contrastText};
    background-color: ${({ theme, }) => theme.primary.main};
    border: 1px solid transparent;
`

export const ConfirmModalStyle = {
    ConfirmContainer,
    ConfirmationText,
    AlertText,
    ContainerWrapper,
    OrderDetailContainer,
    OrderContainer,
    Idtext,
    CodeText,
    ButtonContainer,
    CancelButton,
    ConfirmButton,
}