import React from "react"

import {
  Content
} from "../displays/styles/ToastStyles"
import { Duration, ToastContentType } from "components/providers/ToastProvider"

type PropsType = {
  onClick: () => void,
  content: React.ReactNode,
  duration: Duration,
  type?: ToastContentType,
}

const Toast = ({ content, onClick, duration, type, }: PropsType) => {
  const [contentState, setContentState,] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setContentState(false)
    }, duration - 300)
  }, [])


  return (
    <Content onClick={onClick} contentState={contentState} type={type}>
      {content}
    </Content>)
}
export default Toast