import React from "react"
import Loader from "./Loader"
import {
  Container,
  Content,
  SubTitle,
  Title,
} from "./styles/LodingWrapperStyles"

const LoadingWrapper = () => {
  return (
    <Container>
      <Content>
        <Loader />
        <Title>Cargando...</Title>
        <SubTitle>Espere un momento, por favor.</SubTitle>
      </Content>
    </Container>
  )
}

export default LoadingWrapper