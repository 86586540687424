import React from "react"
import styled from "styled-components"

const ToastMsg = styled.p`
  font-size: 18px;
  color: ${({ theme, }) => theme.primary.contrastText};
`

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 15px;
`

const ToastOrderId = styled.p`
  font-size:22px;
  font-weight:600;
  text-transform: uppercase;
`
const ToastContent = (props: { alternativeId: string, msg: string, }) => {
    return <ToastContainer>
        <ToastMsg>No se pudo comenzar la preparación del pedido.</ToastMsg>
        <ToastOrderId>{props.alternativeId}</ToastOrderId>
    </ToastContainer>
}

export default ToastContent