/* eslint-disable max-lines-per-function */
import * as React from "react"
import { ConnectionState } from "gdu-vq-ordering-api-common/src/utils/RTClient"
import { useRecoilState, } from "recoil"
import { SectionOrder } from "../../api/ApiClient"
import { useRequest } from "../../hooks/useRequest"
import { OrchestratorClient } from "../../rt/OrchestratorClient"
import { orderAtom, storeAtom, } from "../../state"
import { getUserInfo } from "services/session"
import { useEffect } from "react"
import produce from "immer"
import { useCurrent } from "../../hooks/useCurrent"
import {
    ORTOP_Direction, ORTOP_Messages, ORTOP_Type,
} from "gdu-vq-ordering-api-common/src/messages/OrchestratorToOperator"
import ModalComp from "components/displays/ModalComp"
import LoadingWrapper from "components/displays/LoadingWrapper"
import { Duration, ToastContentType, useToaster } from "components/providers/ToastProvider"
import NewOrderToast from "components/displays/NewOrderToast"
import {
    ORTOS_Direction, ORTOS_Messages, ORTOS_Type,
} from "gdu-vq-ordering-api-common/src/messages/OrchestratorToStore"

import playSound from "../../util/soundHelper"

type Props = {
    children?: React.ReactNode,
}


const useSocketConnect = (storeId: string,) => {
    const [connectionState, setConnectionState,]
        = React.useState<ConnectionState>(OrchestratorClient.connectionState)

    React.useEffect(() => {
        OrchestratorClient.onConnectionStateChange(setConnectionState)

        OrchestratorClient.connect({
            token: getUserInfo()!.token,
            storeId: storeId,
        })

    }, [])

    return connectionState
}

export const OrderObserver = (props: Props) => {
    const [orders, setOrders,] = useRecoilState(orderAtom)
    const currentOrders = useCurrent(orders)
    const toaster = useToaster()
    const [store, setStore,] = useRecoilState(storeAtom)

    useSocketConnect(store!.id)

    const initialData = useRequest({
        key: "initialData",
        func: () => SectionOrder.getOrder("POST", {}),
    })

    const currentInitalData = useCurrent(initialData)

    useEffect(() => {
        if (!initialData.loading && !initialData.error) {
            setOrders(initialData.data!)
        }
    }, [initialData,])

    React.useEffect(() => {
        OrchestratorClient.onEvent2<ORTOP_Messages>(ORTOP_Direction, {
            [ORTOP_Type.orderStateChange]: (m) => {
                if (!currentInitalData.current.loading) {
                    if (currentOrders.current.find(o => o.id === m.orderId)) {
                        setOrders(produce(os => {
                            const toUpdate = os.find(o => o.id === m.orderId)!
                            toUpdate.state = m.newState
                        }))
                    } else {
                        initialData.mutate()
                    }
                }
            },
            [ORTOP_Type.orderCompleted]: (m) => {
                if (!currentInitalData.current.loading) {
                    if (currentOrders.current.find(o => o.id === m.orderId)) {
                        setOrders(currentOrders.current.filter(o => o.id !== m.orderId))
                    } else {
                        initialData.mutate()
                    }
                }
            },
            [ORTOP_Type.orderCreated]: (m) => {
                if (!currentInitalData.current.loading) {
                    if (currentOrders.current.every(o => o.id !== m.order.id)) {
                        setOrders(produce(os => [...os, m.order,]))
                        toaster.toast({
                            content: <NewOrderToast
                                order={m.order}
                            />,
                            type: ToastContentType.message,
                            duration: Duration.long,
                        })
                        playSound()
                    } else {
                        initialData.mutate()
                    }
                }
            },
        })
    }, [])

    React.useEffect(() => {
        OrchestratorClient.onEvent2<ORTOS_Messages>(ORTOS_Direction, {
            [ORTOS_Type.orderReceptionStatus]: (m) => {
                if (store!.sectionInfo.id === m.section.id) {
                    setStore(produce(s => {
                        s!.sectionInfo.activeSection = m.active
                    }))
                }
            },
        })
    }, [])

    if (initialData.error) {
        throw new Error("Error getting data")
    }

    if (initialData.loading) {
        return (
            <ModalComp
                modalIsOpen={true}
            >
                <LoadingWrapper />
            </ModalComp>
        )
    }


    return <>
        {props.children}
    </>
}