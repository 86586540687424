import styled from "styled-components"

export const Wrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100%;
  z-index:10000;
  position:fixed;
  top:0;
  left:0;
  background-color:#FFF;
`
export const Content = styled.div`
  width:257px;
  height:240px;
  background-color: #f1f1f1;
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  border-radius:24px;
`

export const Title = styled.p`
  font-size:22px;
  font-weight:600;
  margin-top:20px;
`
export const SubTitle = styled.p`
  font-size:14px;
  font-weight:400;
  margin-top:4px;
`

export const Icon = styled.img`
  width:60px;
  height:60px;
`