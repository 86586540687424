import { generateUrls } from "gdu-vq-common/src/api/urls/ApiUrlsOperator"
import { EnvHelper } from "../util/EnvHelper"
import { getControllerProxy, ProxyOptions } from "gdu-vq-common/src/api/ApiProxy"
import {
    IOperatorAuthController
} from "gdu-vq-common/src/api/interfaces/operator/OperatorAuthController"
import {
    IOperatorOrderController
} from "gdu-vq-common/src/api/interfaces/operator/OperatorOrderController"
import {
    IOperatorStoreController
} from "gdu-vq-common/src/api/interfaces/operator/OperatorStoreController"
import { getUserInfo, closeUserSession, setUserInfo, } from "services/session"
const getUrls = generateUrls(() => EnvHelper.baseUrlCore)

const redirectToLogin = () => {
    const brand: string = window.location.pathname.split("/").filter(Boolean)[0]
    window.location.href = `${window.location.origin}/${brand}`
}

const options: ProxyOptions = {
    getApiCode: () => EnvHelper.apiCodeCore,
    getToken: () => getUserInfo()?.token,
    onAuthError: () => {
        closeUserSession()
        redirectToLogin()
    },
    onRefreshToken: (refresh) => {
        const storageContent = getUserInfo()
        if (storageContent) {
            setUserInfo({ ...storageContent, token: refresh.token, })
        } else {
            closeUserSession()
            redirectToLogin()
        }
    },
}

export const SessionClient = getControllerProxy<IOperatorAuthController>(
    getUrls().operatorAuth,
    options
)

export const SectionStore = getControllerProxy<IOperatorStoreController>(
    getUrls().sectionStore,
    options
)

export const SectionOrder = getControllerProxy<IOperatorOrderController>(
    getUrls().sectionOrder,
    options
)
