import React, { ReactChild } from "react"
import Header from "components/displays/Header"
import { Wrapper } from "./LayoutStyles"

type Props = {
    children: ReactChild | ReactChild[],
    withHeader?: boolean,
}

const Layout = ({ children, withHeader, }: Props) => (
    <Wrapper>
        {withHeader && <Header />}
        {children}
    </Wrapper>
)

export default Layout
