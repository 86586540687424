import { useMemo } from "react"
import * as yup from "yup"

export type LoginForm = { user: string, psw: string, }


export const useFormValidation = () => {
    return useMemo(() => {
        const schema: yup.SchemaOf<LoginForm> = yup.object().shape({
            user: yup.string()
                .ensure(),
            psw: yup.string()
                .ensure(),
        })
        return schema
    }, [])
}

export const getDefaultValues = () => ({ user: "", psw: "", })