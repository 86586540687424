import { ToastContentType } from "components/providers/ToastProvider"
import styled, { keyframes } from "styled-components"

const enterAnimation = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
`

const leaveAnimation = keyframes`
  0%{
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`

export const Wrapper = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`

type ContentPropsType = {
  contentState: boolean,
  type?:string,
}
export const Content = styled.div < ContentPropsType>`
  display: flex;
  width: 98%;
  min-height: 30px;
  background-color: ${({ type, theme, }) => (type === ToastContentType.message ? theme.primary.main : theme.palette.common.dark)};
  border-radius: 12px;
  margin-top: 15px;
  padding: 12px;
  box-sizing: border-box;
  color: #fff;
  /* transform: translateY(-100%); */
  animation-name: ${({ contentState, }) => (contentState ? enterAnimation : leaveAnimation)};
  animation-duration: 0.3s;
  `
