import React, { ReactChild, useEffect, useState } from "react"
import {
    OperatorOrder
} from "gdu-vq-common/src/api/interfaces/operator/models/OperatorOrder"
import OrderPreparationDetail from "./OrderPreparationDetail"
import { SectionOrder } from "api/ApiClient"
import {
    OrderContainer,
    OrderDetailContainer,
    ProductContainer,
    OrderFinalizeButton,
    Wrapper,
    LoaderWrapper,
    LoaderContainer,
    OrderDetailWrapper,
    OrderFinalizeButtonWrapper,
    InlineNotificationWrapper,
} from "../OrderPreparationStyle/OrderPreparationListStyle"
import ConfirmCompleteModal from "./ConfirmCompleteModal"
import produce from "immer"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { orderAtom, selectedActiveOrderIDAtom } from "state"
import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import LoadingWrapper from "components/displays/LoadingWrapper"
import { Duration, ToastContentType, useToaster } from "components/providers/ToastProvider"
import ToastContent from "pages/order/util/ErrorToast"
import {
    ContentWrapper,
    Time,
    TimeWrapper,
    Title,
    TitleWrapper,
    Subtitle
} from "pages/order/orderList/styles/OrderStyles"
import { calculateDiff } from "util/CalculateTimeDiff"
import InlineNotification from "components/displays/InlineNotification"

const WrapperComponent = (props: {
    children: ReactChild | ReactChild[],
    loading: boolean,
}) => {
    if (props.loading) {
        return <>
            <Wrapper>
                {props.children}
            </Wrapper>
            <LoaderWrapper>
                <LoaderContainer>
                    <LoadingWrapper />
                </LoaderContainer>
            </LoaderWrapper>
        </>
    } else {
        return <>{props.children}</>
    }
}

const orderFinalize = async (
    canceledInProgress: boolean,
    orderId: string,
    missingProducts: string[],
) => {
    if (canceledInProgress) {
        return await SectionOrder.cancelOrder("POST", {
            orderId: orderId,
        })
    } else {
        return await SectionOrder.finishOrder("POST", {
            orderId: orderId,
            productsMissing: missingProducts,
        })
    }
}

// eslint-disable-next-line max-lines-per-function
const OrderPreparationList = (props: { order: OperatorOrder, }) => {
    const setOrders = useSetRecoilState(orderAtom)
    const { order, } = props
    const [orderComplete, setOrderComplete,] = useState(false)
    const [missingProducts, setMissingProducts,] = React.useState<string[]>([])
    const canceledInProgress = order.state === OrderState.canceledInProgress
    const [loading, setLoading,] = useState(false)
    const toaster = useToaster()
    const [estimatedTime, setEstimatedTime,] = useState(calculateDiff(order.estimatedDate))
    const selectedActiveOrderId = useRecoilValue(selectedActiveOrderIDAtom)

    useEffect(() => {
        const interval = setInterval(() => {
            setEstimatedTime(calculateDiff(order.estimatedDate))
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    const handleToast = () => {
        toaster.toast({
            content: <ToastContent
                alternativeId={order.alternativeId}
                msg="No se pudo finalizar la preparación del pedido."
            />,
            type: ToastContentType.error,
            duration: Duration.medium,
        })
    }

    const toggleProductAvailability = (id: string) => {
        if (missingProducts.includes(id)) {
            return setMissingProducts(missingProducts.filter(p => p !== id))
        }
        return setMissingProducts([...missingProducts, id,])
    }
    const handleOnDone = async () => {
        setLoading(true)
        try {
            const response = await orderFinalize(canceledInProgress, order.id, missingProducts)
            if (response.ok) {
                if (response.content.stateChanged) {
                    const order = response.content.order
                    setOrders(produce(os => os.filter(o => o.id !== order.id)))
                } else {
                    setLoading(false)
                    handleToast()
                }
            }
        } catch (e) {
            setLoading(false)
            handleToast()
        }
    }

    const handleClickFinalize = () => {
        if (canceledInProgress) {
            handleOnDone()
        } else {
            setOrderComplete(true)
        }
    }

    return <>
        <OrderContainer selected={order.id === selectedActiveOrderId}>
            <WrapperComponent loading={loading} >
                <OrderDetailWrapper>
                    <OrderDetailContainer>
                        <ContentWrapper>
                            <TitleWrapper>
                                <Subtitle>Código identificador</Subtitle>
                                <Title>{order.alternativeId}</Title>
                            </TitleWrapper>
                            <TimeWrapper state={order.state}>
                                <Time state={order.state}>
                                    {estimatedTime}
                                </Time>
                                Min de espera
                            </TimeWrapper>
                        </ContentWrapper>
                    </OrderDetailContainer>
                </OrderDetailWrapper>
                {canceledInProgress ? <InlineNotificationWrapper>
                    <InlineNotification>
                        El pedido ha sido cancelado
                    </InlineNotification>
                </InlineNotificationWrapper> : <></>}

                <ProductContainer disable={canceledInProgress}>
                    {order.orderLine.map(ol =>
                        <OrderPreparationDetail
                            key={ol.productId}
                            orderLine={ol}
                            disable={canceledInProgress}
                            showComment={true}
                        />
                    )}
                </ProductContainer>
                <OrderFinalizeButtonWrapper>
                    <OrderFinalizeButton
                        disabled={loading}
                        onClick={handleClickFinalize}
                    >
                        {canceledInProgress ? "Entendido" : "Finalizar"}
                    </OrderFinalizeButton>
                </OrderFinalizeButtonWrapper>

            </WrapperComponent>
        </OrderContainer>
        {orderComplete && <ConfirmCompleteModal
            missingProducts={missingProducts}
            toggleProductAvailability={toggleProductAvailability}
            orderPrepare={order}
            openModal={!!orderComplete}
            onClose={() => setOrderComplete(false)}
            onDone={handleOnDone}
        />}
    </>
}

export default OrderPreparationList
