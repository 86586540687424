import React, { useState } from "react"
import { OperatorOrder } from "gdu-vq-common/src/api/interfaces/operator/models/OperatorOrder"
import { SectionOrder } from "api/ApiClient"
import ConfirmModal from "pages/order/orderList/ConfirmModal"
import Order from "./Order"
import { useRecoilState, useSetRecoilState } from "recoil"
import { orderAtom, selectedActiveOrderIDAtom } from "state"
import produce from "immer"
import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import { Duration, ToastContentType, useToaster } from "components/providers/ToastProvider"
import ToastContent from "../util/ErrorToast"


const OrderByState = (props: { orders: OperatorOrder[], }) => {
    const [
        selectedActiveOrderId,
        setSelectedActiveOrderId,
    ] = useRecoilState(selectedActiveOrderIDAtom)
    const setOrders = useSetRecoilState(orderAtom)
    const orders = props.orders
    const [orderPrepare, setPrepare,] = useState<OperatorOrder>()
    const [loading, setLoading,] = useState<string[]>([])
    const toaster = useToaster()

    const handleToast = (id: string) => {
        toaster.toast({
            content: <ToastContent
                alternativeId={id}
                msg="No se pudo comenzar la preparación del pedido."
            />,
            type: ToastContentType.error,
            duration: Duration.short,
        })
    }

    const handleOrderToProgress = (o: OperatorOrder) => async () => {
        setLoading([...loading, o.id,])
        try {
            const response = await SectionOrder.ordenStartPreparation("POST", {
                orderId: o.id,
            })
            if (response.content.stateChanged) {
                setOrders(produce(os => {
                    const toUpdate = os.find(o => o.id === response.content.order!.id)!
                    toUpdate.state = response.content.order!.state
                }))
            } else {
                handleToast(o.alternativeId)
                setLoading(loading.filter(l => l !== o.id))
            }
        } catch (e) {
            handleToast(o.alternativeId)
            setLoading(loading.filter(l => l !== o.id))
        }
    }

    const handleClick = (order: OperatorOrder, index: number) => {
        if (order.state === OrderState.onHold) {
            setPrepare(order)
        }

        if (order.state === OrderState.inProgress) {
            setSelectedActiveOrderId(order.id === selectedActiveOrderId ? null : order.id)
        }
    }

    return (<>
        {orders.map((order, index) => (
            <Order
                key={order.id}
                alternativeId={order.alternativeId}
                estimatedDate={order.estimatedDate}
                state={order.state}
                disable={order.state === OrderState.onHold && index > 0}
                loading={!!loading.includes(order.id)}
                handleClick={() => handleClick(order, index)}
                selected={order.id === selectedActiveOrderId}
                runNewAnimation={order.state === OrderState.onHold && index === 0}
            />
        ))}
        {orderPrepare && <ConfirmModal
            orderPrepare={orderPrepare}
            openModal={!!orderPrepare}
            onClose={() => setPrepare(undefined)}
            onDone={handleOrderToProgress(orderPrepare)}
        />}
    </>)
}

export default OrderByState