import styled from "styled-components"

export const LogoContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
`

export const LogoBrand = styled.img`
    max-height: 130px;
    max-width: 220px;
`

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90%;
`
export const TitleLogin = styled.h2`
    font-size: 24px;
    font-weight: 600;
`
export const SpanTitle = styled.span`
    color: ${({ theme, }) => theme.primary.main};
`

export const ButtonLogin = styled.button`
    height: 64px;
    width: 342px;
    margin-top: 8px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 12px;
    color: ${({ theme, }) => theme.primary.contrastText};
    background-color: ${({ theme, }) => theme.palette.common.dark};
`

export const Form = styled.form`
    margin-top: 26px;
    width: 342px;
    height: 230px;
    align-content: center;
`

export const Label = styled.label`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
`

export const ErrorContainer = styled.div`
    margin-top: 8px;
`

export const ErrorMsg = styled.p`
    display: block;
    text-align: center;
    color: ${({ theme, }) => theme.error.main};
`