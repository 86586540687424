import { EnvHelper } from "../util/EnvHelper"
import {
    ORTOP_Direction,
    ORTOP_Messages,
} from "gdu-vq-ordering-api-common/src/messages/OrchestratorToOperator"
import {
    ORTOS_Direction,
    ORTOS_Messages,
} from "gdu-vq-ordering-api-common/src/messages/OrchestratorToStore"
import {
    OPTOR_Messages,
} from "gdu-vq-ordering-api-common/src/messages/OperatorToOrchestrator"
import { RTClient } from "gdu-vq-ordering-api-common/src/utils/RTClient"


export const OrchestratorClient = new RTClient<
    ORTOP_Messages | ORTOS_Messages, OPTOR_Messages
>(EnvHelper.baseUrlRT, [ORTOP_Direction, ORTOS_Direction,])