import React from "react"
import { Modals } from "styles/ModalStyle"
import ModalComp from "components/displays/ModalComp"
import { OperatorOrder } from "gdu-vq-common/src/api/interfaces/operator/models/OperatorOrder"
import { ConfirmModalStyle } from "./styles/ConfirmModalStyle"
import { PreparationTime } from "components/displays/PreparationTime"

const {
    ConfirmContainer,
    ConfirmationText,
    AlertText,
    ContainerWrapper,
    OrderDetailContainer,
    OrderContainer,
    Idtext,
    CodeText,
    ButtonContainer,
    CancelButton,
    ConfirmButton,
} = ConfirmModalStyle

const ConfirmModal = (props: {
    openModal: boolean,
    orderPrepare: OperatorOrder,
    onClose: () => void,
    onDone: () => void,
}) => {
    return (
        <ModalComp
            modalIsOpen={props.openModal}
            modalStyle={Modals.confirm}
            closeModal={props.onClose}
        >
            <ConfirmContainer>
                <ContainerWrapper>
                    <ConfirmationText>
                        ¿Deseas comenzar a la preparación del pedido?
                    </ConfirmationText>
                    <AlertText>
                        al hacerlo, el estado cambiará a “en preparación”.
                    </AlertText>
                    <OrderDetailContainer>
                        <OrderContainer>
                            <Idtext>{props.orderPrepare.alternativeId}</Idtext>
                            <CodeText>
                                código identificador
                            </CodeText>
                        </OrderContainer>
                        <PreparationTime
                            time={props.orderPrepare.estimatedDate}
                            text={"de espera"}
                        />
                    </OrderDetailContainer>
                </ContainerWrapper>
                <ButtonContainer>
                    <CancelButton
                        onClick={props.onClose}
                    >
                        Cancelar
                    </CancelButton>
                    <ConfirmButton
                        onClick={() => {
                            props.onDone()
                            props.onClose()
                        }}
                    >
                        Comenzar preparación
                    </ConfirmButton>
                </ButtonContainer>
            </ConfirmContainer>
        </ModalComp>
    )
}

export default ConfirmModal