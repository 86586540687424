import React from "react"
import { connectField } from "immer-form"
import { StyledInput, StyledSpan, } from "./style/InputStyle"

export type Props = {
    type: "text" | "password",
    placeholder?: string,
    value: string,
    disabled?: boolean,
    errorMessage?: string | null,
    onChange?: (value: string) => void,
    onBlur?: () => void,
    onFocus?: (value: string) => void,
}

const Input = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(e.target.value)
    }

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        props.onFocus && props.onFocus(e.target.value)
    }

    return <>
        <StyledInput
            type={props.type}
            value={props.value || ""}
            ref={ref}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onChange={handleOnChange}
            onBlur={props.onBlur}
            onFocus={handleOnFocus}
        />
        {props.errorMessage ? (
            <StyledSpan>
                {props.errorMessage}
            </StyledSpan>
        ) : null}
    </>
})

export default Input

export const InputField = connectField(Input)
