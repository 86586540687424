import { DefaultTheme } from "styled-components"

const common = {
    dark: "#333333",
    grey: "#E6E6E6",
    greyFocus: "#C6C6C6",
    grey1: "#828282",
    grey2: "#E0E0E0",
    grey3: "#00000008",
    white: "#FFFFFF",
    white1: "#F6F9FB",
    yellow: "#FFF7DC",
    yellow1: "#9F893C",
    yellow2: "#c9930921",
    red: "#BD111B",
    red2: "#f9ecec",

}

export const RebrandingThemes: DefaultTheme = {
    palette: {
        common,
    },
    primary: {
        main: "#006937",
        contrastText: "#fafafa",
        red: "#EB4B4B",
    },
    disabled: {
        main: "#00000073",
    },
    secondary: {
        main: "rgba(255, 56, 56, 0.74)",
        contrastText: "#4F0303",
    },
    error: {
        main: "#BD111B",
        background: "#F9E6E6",
        contrastText: "#f9ecec",
    },
}