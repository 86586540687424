import styled from "styled-components"


export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
`

export const DetailTitle = styled.p`
    font-size: 30px;
    font-weight: 600;
    vertical-align: middle;
    line-height: 20px;
    margin-bottom: 17px;
    padding: 0 0 15px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

export const OrdersContainer = styled.div`
  scroll-behavior: smooth;
  flex: 1;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  padding: 0 22px 15px 22px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
    
`