import {
    StoreSectionProducts
} from "gdu-vq-common/src/api/interfaces/consumer/models/ConsumerStore"
import { ObjDictionary } from "gdu-vq-common/src/helpers/toDictionary"

export enum OperatorOrderState {
    onHold = "ON_HOLD",
    closed = "CLOSED",
    active = "ACTIVE",
}
export type SectionProducts = {
    products: StoreSectionProducts[] | undefined,
    productsById?: ObjDictionary<string, StoreSectionProducts> | null,
    lastUpdate?: Date | null,
}

export type CartItem = {
    productId: string,
    quantity: number,
    comment?: string,
}

export type Cart = CartItem[]

export type BranchOffice = {
    id: number,
    region: string,
    location: string,
    type: string,
    name: string,
    officeSlug: string,
    sections: string[],
}

export type Supermarket = {
    id: number,
    supermarket: string,
    slug: string,
    branchOffices: BranchOffice[],
};

export type UserInfo = {
    userName: string,
    token: string,
}
