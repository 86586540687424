import styled from "styled-components"
export const StyledInput = styled.input`
    height: 64px;
    padding-left: 16px;
    margin-top: 8px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 12px;
    background-color: ${({ theme, }) => theme.palette.common.grey};
    :hover {
        background-color: ${({ theme, }) => theme.palette.common.greyFocus};
    }
`

export const StyledSpan = styled.span`
    display: block;
    text-align: center;
    color: ${({ theme, }) => theme.error.main};
`