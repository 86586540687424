import styled, { css } from "styled-components"

export const ConfirmContainer = styled.div`
    padding: 12px 0px;
    height: 100%;
`

export const ConfirmationText = styled.p`
    font-size: 22px;
    font-weight: 400;
    line-height: 140%;
    text-align: left;
    color:${({ theme, }) => theme.palette.common.dark};
    margin-bottom: 12px;
    text-transform: uppercase;
`

export const OrderDetailContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #f6f6f6;
    margin-bottom: 25px;
    padding: 12px 16px;
    border-radius: 15px;
    border: 1px solid ${({ theme, }) => theme.palette.common.grey2}
`

export const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction:column;
  width: 100%;
`

export const OrderConfirmProductsContainer = styled.div`
    margin-bottom: 40px;
    max-height: 380px;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    padding: 8px;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const DefaultButton = css`
    padding: 12px;
    font-size: 14px;
    font-weight: 300;
    color:${({ theme, }) => theme.palette.common.dark};
    border-radius: 14px;
    flex: 0 0 calc(50% - 4px);
`

export const CancelButton = styled.button`
    ${DefaultButton}
    color: ${({ theme, }) => theme.palette.common.dark};
    background-color: ${({ theme, }) => theme.primary.contrastText};
    border: 1px solid #9A9A9A;
`

export const ConfirmButton = styled.button`
    ${DefaultButton}
    color: ${({ theme, }) => theme.primary.contrastText};
    background-color: ${({ theme, }) => theme.primary.main};
    border: 1px solid transparent;
`