import React from "react"
import ReactDOM from "react-dom"
import { RecoilRoot } from "recoil"
import AppRouter from "router/AppRouter"
import "./index.css"
import type { } from "styled-components/cssprop"
import reportWebVitals from "./reportWebVitals"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { EnvHelper } from "./util/EnvHelper"
import styled from "styled-components"
import ErrorModal from "components/displays/ErrorModal"


if (EnvHelper.isProduction) {
  Sentry.init({
    dsn: EnvHelper.sentryDSN,
    release: EnvHelper.buildName,
    environment: EnvHelper.envType,
    integrations: [new BrowserTracing(),],
    tracesSampleRate: EnvHelper.sentryIntegrationsSampleRate,
  })
} else {
  // eslint-disable-next-line no-console
  console.warn("RemoteDiagnosticsSentry Disabled")
}


(() => {
  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty("--app-height", `${window.innerHeight}px`)
  }
  window.addEventListener("resize", appHeight)
  appHeight()
})()


const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: var(--app-height);
  font-weight: 300;
  padding-top: 15px;
  
`

const fallback = <ErrorModal />

const AppRouterErrorBoundary = Sentry.withErrorBoundary(AppRouter, {
  fallback,
})

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <StyledContainer>
        <AppRouterErrorBoundary />
      </StyledContainer>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
