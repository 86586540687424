import React, { useEffect, useState } from "react"
import { OperatorOrderLine } from "gdu-vq-common/src/api/interfaces/operator/models/OperatorOrder"
import {
    ContainerDetail,
    ProductImg,
    QtyText,
    CommentText,
    DescriptionP,
    DescriptionPWrapper,
    ProductImgDiv,
    ProductItemImgDiv,
    ProductDescriptionWrapper,
    MissingProduct,
    MissingProductWrapper,
} from "../OrderPreparationStyle/OrderPreparationDetailStyle"
import { incrementToReadable } from "gdu-vq-common/src/api/interfaces/shared/helpers/ProductUtil"
import { defaultProductImg } from "components/utils/brandsImg/brandsImage"
import { useRecoilValue } from "recoil"
import { storeAtom } from "state"
import { SectionType } from "gdu-vq-common/src/api/interfaces/shared/models/Section"
import { IconWrapper } from "components/displays/Elements"


const ProductImage = (props: {
    imageUrl: string,
    sectionType: SectionType,
}) => {
    const [imgUrl, setImgUrl,] = useState(props.imageUrl)

    useEffect(() => {
        setImgUrl(props.imageUrl)
    }, [props.imageUrl,])

    const handleChangeImgUrl = () => {
        setImgUrl(defaultProductImg[props.sectionType])
    }

    return <ProductImg
        src={imgUrl}
        onError={handleChangeImgUrl}
    />
}

type PropsType = {
    orderLine: OperatorOrderLine,
    onClick?: () => void,
    showComment?: boolean,
    missing?: boolean,
    disable?: boolean,
}

const OrderPreparationDetail = (props: PropsType) => {
    const { orderLine: ol, onClick, missing, } = props
    const { description, imgUrl, unit, increments, } = ol.product!
    const section = useRecoilValue(storeAtom)!
    const {
        unit: readableUnit,
    } = incrementToReadable(increments, unit, ol.quantity)
    return (
        <ContainerDetail
            style={{
                cursor: onClick ? "pointer" : "default",
                opacity: missing ? 0.5 : 1,
            }}
            onClick={onClick}
        >
            <ProductImgDiv>
                <ProductDescriptionWrapper>
                    <ProductItemImgDiv>
                        <ProductImage
                            imageUrl={imgUrl || defaultProductImg[section.sectionInfo.sectionType]}
                            sectionType={section.sectionInfo.sectionType}
                        />
                    </ProductItemImgDiv>
                    <div>
                        <DescriptionPWrapper>
                            <DescriptionP>{description}</DescriptionP>
                        </DescriptionPWrapper>
                        {missing ? <MissingProductWrapper>
                            <IconWrapper>!</IconWrapper>
                            <MissingProduct>Producto Faltante</MissingProduct>
                        </MissingProductWrapper> : <QtyText>{ol.quantity} {readableUnit}</QtyText>}

                    </div>

                </ProductDescriptionWrapper>
                {ol.comment && <CommentText> {ol.comment} </CommentText>}
            </ProductImgDiv>
        </ContainerDetail >
    )
}

export default OrderPreparationDetail