import styled from "styled-components"

export const Container = styled.div`
  display:flex;
  align-items: center;
  height: 100%;
  padding: 6px 0;
`

export const SectionImgWrapper = styled.div`
  background-color: ${({ theme, }) => theme.primary.main};
  padding: 8px;
  border-radius: 12px;
`

export const SectionImg = styled.img`
  width: 48px;
  height: 48px;
  filter: invert();
`

export const BrandImg = styled.img`
  margin-right: 8px;
`
export const LocationContainer = styled.div`
  display: flex;
  margin-left: 12px;
  flex-direction: row;
`

export const BrandP = styled.p`
  font-weight: 700;
  margin-right: 5px;
  font-size: 14px;
  ::after { 
        content: " |";
  };
`

export const LocationP = styled.p`
  font-size: 14px;
`

export const SectionDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-height: 48px;
  align-items: left;
  text-transform: uppercase;
`

export const SectionTitle = styled.h1`
    margin-left: 12px;
    font-size: 24px;
    line-height: 20px;
`


export const BrandLogo = styled.img`
  max-height: 26px;
`

export const OrderReceptionIconContainer = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
height: 100%;

p {
  margin-right: 12px;
  color: red;
}
`

export const Incons = styled.img`
  max-height: 55px;
  margin-right: 15px;
  height: 100%;
`

export const OrderReceptionContainer = styled.div`
  position: relative;
  height: 100%;
`

export const OrderReceptionSpace = styled.div`
  position: absolute;
  border-right: 10px solid transparent;
  border-top: 6px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid rgba(0,0,0,0.03);
  filter: drop-shadow(0px 8px 8px rgba(0,0,0,0.03));
`

export const OrderReceptionContent = styled.div`
  position: absolute;
  background: white;
  border-radius: 16px;
  box-shadow: -4px 0px 16px 4px rgba(0, 0, 0, 0.02), 0px -4px 16px 4px rgba(0, 0, 0, 0.02), 8px 0px 16px 4px rgba(0, 0, 0, 0.02), 0px 6px 16px 4px rgba(0, 0, 0, 0.04);
  min-width: max-content;
  padding: 24px;
  width: auto;
  top: calc(100% + 15px);
  right: 0;
`

export const Text = styled.div`
  text-transform: uppercase;
  font-size: 14px;
`

export const DisableContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0px;
`
export const StateText = styled.div`
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
`

type DisableProps = {
  active: boolean, disable: boolean,
}

export const DisableBar = styled.div<DisableProps>`
  pointer-events: ${({ disable, }) => (disable ? "none" : "auto")};
  display: flex;
  align-items: center;
  justify-content: ${({ active, }) => (active ? "right" : "left")};
  background-color: lightgrey;
  border-radius: 12px;
  padding: 6px;
  width: 49px;
  height: 25px;
  margin-right: 12px;
`
export const Circle = styled.div<{ active: boolean, }>`
  height: 19px;
  width: 19px;
  background-color: ${({ theme, active, }) => (active ? theme.primary.main : theme.palette.common.grey)};
  border-radius: 100%;
`

export const MessageContainer = styled.div`
  display: flex;
  max-width: 270px;
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ theme, }) => theme.error.background};
`

export const MessageText = styled.p`
  color: ${({ theme, }) => theme.error.main};
  margin-left: 8px;
  font-weight: 400;
`

export const SpanMessage = styled.span`
  font-weight: 600;
`