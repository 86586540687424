import styled from "styled-components"

export const TimeP = styled.p<{ disable: boolean, }>`
    padding: 4px 12px 4px 12px;
    border-radius: 8px;
    margin-top:4px;
    background-color: ${({ theme, disable, }) => (
        disable ? theme.palette.common.dark : theme.primary.main
    )};
    color:${({ theme, }) => theme.primary.contrastText};
    font-weight: 500;
    font-size: 16px;
    border-radius: 8px;
    text-align:center ;
`

export const TextP = styled.p<{ disable: boolean, }>`
    text-align: center;
    line-height: 130%;
    letter-spacing: -0.2px;
    margin: 2px 0px;
    color: ${({ theme, disable, }) => (
        disable ? theme.palette.common.dark : theme.primary.main
    )};
    font-weight: 500;
    font-size: 9px;
`

export const StyledDiv = styled.div<{ disable: boolean, }>`
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    opacity: ${({ disable, }) => (disable ? 0.4 : 1)};
`