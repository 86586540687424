import React from "react"

import { Content, OrderId, Title, Wrapper, Time, TimeWrapper  } from "./styles/NewOrderToastStyles"
import { OperatorOrder } from "gdu-vq-common/src/api/interfaces/operator/models/OperatorOrder"
import { calculateDiff } from "util/CalculateTimeDiff"


type PropsType = {
    order:OperatorOrder,
}

const NewOrderToast = ({order,}:PropsType) => {
    const id = order.alternativeId

    const estimatedTime=calculateDiff(order.estimatedDate)
    return (<Wrapper>
        <Content>
            <Title>nuevo pedido</Title>
            <OrderId>{id}</OrderId>
        </Content>
        <TimeWrapper>
          <Time>
            {estimatedTime} MIN
          </Time>
          DE ESPERA
        </TimeWrapper>
        </Wrapper>)
}


export default NewOrderToast