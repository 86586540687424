import React, { useRef, useEffect } from "react"
import { OperatorOrder } from "gdu-vq-common/src/api/interfaces/operator/models/OperatorOrder"
import OrderPreparationList from "./orderPreparationDetail/OrderPreparationList"
import {
    DetailTitle,
    OrdersContainer,
    Wrapper,
} from "./OrderPreparationStyle/OrderPreparationStyle"
import { ContentWrapper } from "components/displays/Elements"
import { useRecoilValue } from "recoil"
import { selectedActiveOrderIDAtom } from "state"
import {
    orderPreparationListMargin, orderPreparationListWidth
} from "./OrderPreparationStyle/OrderPreparationListStyle"


const SectionWithoutOrders = () => {
    return <Wrapper>
        <DetailTitle>Detalles de pedido</DetailTitle>
        <ContentWrapper>Comienza a preparar pedidos para ver información adicional.</ContentWrapper>
    </Wrapper>
}
type PropsType = { orders?: OperatorOrder[], }

const OrderPreparation = ({ orders, }: PropsType) => {
    const ordersContainerRef = useRef<HTMLDivElement | null>(null)
    const selectedActiveOrderId = useRecoilValue(selectedActiveOrderIDAtom)

    useEffect(() => {
        if (ordersContainerRef.current && selectedActiveOrderId) {
            const index = orders?.findIndex(({ id, }) => id === selectedActiveOrderId)!
            const width = orderPreparationListWidth + orderPreparationListMargin
            const scroll = width * (Math.max(index - 1, 0))
            ordersContainerRef.current.scrollLeft = scroll
        }
    }, [selectedActiveOrderId,])

    if (orders && orders.length > 0) {
        return (
            <Wrapper>
                <DetailTitle>Detalles de pedido</DetailTitle>
                <OrdersContainer ref={ordersContainerRef}>
                    {orders.map(o =>
                        <OrderPreparationList
                            key={o.id}
                            order={o}
                        />
                    )}
                </OrdersContainer>
            </Wrapper>
        )
    } else {
        return <SectionWithoutOrders />
    }

}

export default OrderPreparation