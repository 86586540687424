import React from "react"
import { Modals } from "../../styles/ModalStyle"
import ModalComp from "./ModalComp"
import {
    ConfirmContainer,
    ButtonContainer,
    CancelButton,
    ConfirmButton,
    QuestionText,
    ContainerAlert,
    WarningImg,
    AlertMessage,
} from "./styles/ConfirmActiveSectionStyles"
import warning from "assets/icons/warning.svg"


const ConfirmActiveSection = (props: {
    active: boolean,
    openModal: boolean,
    onClose: () => void,
    onDone: () => void,
}) => {
    const {
        active, openModal, onClose, onDone,
    } = props

    const question = active
        ? "¿Deseas deshabilitar el modo de recepción de pedidos?"
        : "¿Deseas habilitar el modo de recepción de pedidos?"

    const alert = active
        ? `al hacerlo, la sección no será visible para los clientes, 
        pero si se podrá continuar con los pedidos ya recepcionados.`
        : `al hacerlo, la sección será visible en el sistema de 
        filas virtuales y podrá recibir pedidos.`

    return (
        <ModalComp
            modalIsOpen={openModal}
            modalStyle={Modals.confirm}
            closeModal={onClose}
        >
            <ConfirmContainer>
                <QuestionText>
                    {question}
                </QuestionText>
                <ContainerAlert>
                    <WarningImg src={warning} />
                    <AlertMessage>
                        {alert}
                    </AlertMessage>
                </ContainerAlert>
                <ButtonContainer>
                    <CancelButton
                        onClick={onClose}
                    >
                        Cancelar
                    </CancelButton>
                    <ConfirmButton
                        onClick={() => {
                            onDone()
                            onClose()
                        }}
                    >
                        {active ? "Deshabilitar recepción" : "Habilitar recepción"}
                    </ConfirmButton>
                </ButtonContainer>
            </ConfirmContainer>
        </ModalComp>
    )
}

export default ConfirmActiveSection