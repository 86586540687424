import React, { forwardRef } from "react"
type PropsType = {
    clientName: string,
    orderId: string,
}

const PrintableContent = forwardRef(
    ({ clientName, orderId, }: PropsType,
    ref?: React.Ref<HTMLDivElement>) => {

        return (
        <div ref={ref} style={{fontFamily:"sans-serif",}} >
            <h2 style={{fontSize:"1cm", marginBottom:0,}}>{orderId}</h2>
            <p  style={{fontSize:"0.5cm",}}>CÓDIGO IDENTIFICADOR</p>
            <h2 style={{fontSize:"1cm", marginBottom:0,}}>{clientName}</h2>
            <p style={{fontSize:"0.5cm",}}>CLIENTE</p>
        </div>
    )
})

export default PrintableContent