import { DateTime } from "luxon"

export const calculateDiff = (isoDate: string | null) => {
    if (!!isoDate) {
        const date = new Date(isoDate)
        const now = DateTime.now()
        const dateTime = DateTime.fromJSDate(date)
        const time = dateTime.diff(now, "minutes").toObject()

        if (Math.trunc(time.minutes!) > 0) {
            return Math.trunc(time.minutes!)
        } else {
            return 1
        }
    }
    return null
}