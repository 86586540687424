import SoundFile from "../assets/sounds/sound.mp3"

const Sound = new Audio()
Sound.crossOrigin = "anonymous"
Sound.src = SoundFile
Sound.load()


const playSound = () => {
  Sound.play()
}

export default playSound
