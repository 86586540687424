import React from "react"
import { BrowserRouter, Route, Routes, } from "react-router-dom"
import Protected from "./Protected"
import useSession from "hooks/useSession"
import Login from "pages/login/Login"
import { initialPath } from "util/paths"
import ErrorModal from "components/displays/ErrorModal"

const AppRouter = () => {
    const { isLogged, } = useSession()
    const content = isLogged ? <Protected /> : <Login />

    return (
        <BrowserRouter>
            <Routes>
                <Route path={initialPath} element={content} />
                <Route path="*" element={<ErrorModal />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter
