import styled from "styled-components"


export const NumberOfOrders = styled.span<{ dark?: boolean, }>`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: ${({ theme, dark, }) => (dark ? theme.palette.common.dark : theme.primary.contrastText)};
  color: ${({ theme, }) => theme.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  `

export const Title = styled.span`
  font-size: 16px;
  text-transform: uppercase;
  `

export const OrderSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  background-color:${({ theme, }) => theme.primary.main};
  color: ${({ theme, }) => theme.primary.contrastText};
  font-size: 16px;
  `
export const OrderSection = styled.div`
  position: relative;
  background-color: ${({ theme, }) => theme.palette.common.white1};
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  `

export const OrderListWrapper = styled.div`
  padding-top: 20px;
  width: 390px;
`

export const OrderDetailListWrapper = styled.div`
  padding-top: 20px;
  overflow: hidden;
  flex: 1;
`

export const OrdersWrapper = styled.div`
  height:100%;
  display:flex;
  overflow:hidden;
  flex: 1;
`

export const DetailTitle = styled.p`
    font-size: 16px;
    font-weight: 300;
    padding: 12px 0px;
    vertical-align: middle;
    text-transform: uppercase;
    line-height: 20px;
    margin-bottom: 4px;
`

export const OrdersSectionWrapper = styled.div`
  height: 100%;
  padding: 0 16px 0 16px;
  overflow-y: auto;
`