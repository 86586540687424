import { Brands } from "gdu-vq-common/src/api/interfaces/shared/models/Brand"
import { RebrandingThemes, } from "styles/themes"

export const switchBrands = (brand: Brands) => {
    switch (brand) {
        case Brands.disco:
            return RebrandingThemes
        case Brands.devoto:
            return RebrandingThemes
        case Brands.geant:
            return RebrandingThemes
        default: null
    }
}
