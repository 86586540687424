import {
    OperatorOrder,
} from "gdu-vq-common/src/api/interfaces/operator/models/OperatorOrder"
import {
    BrandStoreInfo,
} from "gdu-vq-common/src/api/interfaces/operator/models/OperatorStore"
import { atom } from "recoil"
import { getUserInfo, } from "services/session"


export const isLoggedAtom = atom<boolean>({
    key: "isLogged", default: (
        () => {
            const storageContent = getUserInfo()
            if (storageContent) {
                return !!storageContent.token
            }
            return false
        })(),
})

export const storeAtom = atom<BrandStoreInfo | null>({
    key: "operatorStore", default: null,
})

export const orderAtom = atom<OperatorOrder[]>({
    key: "operatorOrder", default: [],
})

export const selectedActiveOrderIDAtom = atom<string | null>({
    key: "selectedActiveOrderIDAtom",
    default: null,
})