import { defaultProductImg, imgByBrand } from "components/utils/brandsImg/brandsImage"
import React, { useState } from "react"
import { useRecoilState, } from "recoil"
import { storeAtom } from "state"
import { StyledNav } from "./Elements"
import warning from "assets/icons/warning.svg"
import {
    BrandLogo,
    Incons,
    Container,
    SectionImg,
    SectionDataContainer,
    SectionTitle,
    LocationContainer,
    OrderReceptionContainer,
    OrderReceptionContent,
    BrandImg,
    BrandP,
    LocationP,
    OrderReceptionSpace,
    Text,
    DisableContainer,
    StateText,
    Circle,
    DisableBar,
    MessageText,
    SpanMessage,
    MessageContainer,
    SectionImgWrapper,
    OrderReceptionIconContainer,
} from "./styles/HeaderStyle"
import logout from "assets/icons/logout.svg"
import inactiveSection from "assets/icons/inactiveSection.svg"
import activeSection from "assets/icons/activeSection.svg"
import ModalComp from "./ModalComp"
import { Modals } from "styles/ModalStyle"
import LogOut from "pages/login/Logout"
import { useClickOutside } from "hooks/useClickOutside"
import { SectionStore } from "api/ApiClient"
import produce from "immer"
import ConfirmActiveSection from "./ConfirmActiveSection"
import { ToastMsg, WarningImg } from "./styles/ConfirmActiveSectionStyles"
import { useBrand } from "util/paths"
import { Duration, ToastContentType, useToaster } from "components/providers/ToastProvider"


type Props = {
    isOpen: boolean,
    elementRef: React.MutableRefObject<HTMLDivElement | null>,
    toggleIsOpen: () => void,
    active: boolean,
    setConfirmActive: () => void,
    disable: boolean,
}

const OrderReception = ({
    isOpen, elementRef, toggleIsOpen, active, setConfirmActive, disable,
}: Props) => {
    return <OrderReceptionContainer ref={elementRef} >
        <OrderReceptionIconContainer>
            {!active && <p>Sección deshabilitada</p>}
            <Incons src={active
                ? activeSection : inactiveSection}
                onClick={toggleIsOpen}
            />
        </OrderReceptionIconContainer>

        {isOpen && <>
            <OrderReceptionSpace />
            <OrderReceptionContent>
                <Text>Recepción de pedidos</Text>
                <DisableContainer>
                    <DisableBar
                        disable={disable}
                        active={active}
                        onClick={setConfirmActive}
                    >
                        <Circle active={active} />
                    </DisableBar>
                    <StateText>{active ? "habilitada" : "deshabilitada"}</StateText>
                </DisableContainer>
                {!active && <MessageContainer>
                    <WarningImg src={warning} />
                    <MessageText>
                        La sección no es visible para los nuevos clientes de
                        <SpanMessage> filas virtuales.</SpanMessage>
                    </MessageText>
                </MessageContainer>}
            </OrderReceptionContent>
        </>}
    </OrderReceptionContainer>
}

// eslint-disable-next-line max-lines-per-function
const Header = () => {
    const [store, setStore,] = useRecoilState(storeAtom)!
    const brand = useBrand()

    if (!store) { throw new Error("Invalid Store") }

    const active = store.sectionInfo.activeSection
    const [logoutModal, setLogoutModal,] = useState(false)
    const [disable, setDisable,] = useState(false)
    const [confirmActive, setConfirmActive,] = useState(false)
    const { isOpen, elementRef, toggleIsOpen, } = useClickOutside()
    const toaster = useToaster()

    const handleToast = () => {
        const msg = active ? "deshabilitar" : "habilitar"
        toaster.toast({
            content: <ToastMsg>No se pudo {msg} la sección, intente más tarde.</ToastMsg>,
            type: ToastContentType.error,
            duration: Duration.short,
        })
    }

    const handleOrderRepections = async () => {
        setDisable(true)
        try {
            const newValue = !active
            const response = await SectionStore.ordersReception("POST", {
                reception: newValue,
            })
            if (response.ok) {
                setStore(produce(s => { s!.sectionInfo.activeSection = newValue }))
            } else {
                handleToast()
            }
        } catch (e) {
            handleToast()
        }
        setDisable(false)
    }

    return (<>
        <StyledNav>
            <Container>
                <SectionImgWrapper>
                    <SectionImg src={defaultProductImg[store.sectionInfo.sectionType]} />
                </SectionImgWrapper>
                <SectionDataContainer>
                    <SectionTitle>{store.sectionInfo.sectionType}</SectionTitle>
                    <LocationContainer>
                        <BrandImg src={imgByBrand[brand].locationRed} />
                        <BrandP>{brand} {store.description}</BrandP>
                        <LocationP>{store.location.direccion}</LocationP>
                    </LocationContainer>
                </SectionDataContainer>
            </Container>
            <Container>
                <OrderReception
                    isOpen={isOpen}
                    elementRef={elementRef}
                    toggleIsOpen={toggleIsOpen}
                    active={active}
                    setConfirmActive={() => setConfirmActive(true)}
                    disable={disable}
                />
                <Incons src={logout} onClick={() => setLogoutModal(true)} />
                <BrandLogo src={imgByBrand[brand].brandImg} />
            </Container>
        </StyledNav>
        <ModalComp
            modalIsOpen={logoutModal}
            modalStyle={Modals.confirm}
            closeModal={() => setLogoutModal(false)}
        >
            <LogOut cancel={() => setLogoutModal(false)} />
        </ModalComp>
        {confirmActive && <ConfirmActiveSection
            active={active}
            openModal={confirmActive}
            onClose={() => setConfirmActive(false)}
            onDone={handleOrderRepections}
        />}
    </>
    )
}

export default Header
