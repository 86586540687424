import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import styled, { css, keyframes } from "styled-components"

const newOrderKeyframe = keyframes`
 0% {
  background-color: transparent;
 }

 10% {
  background-color: black;
 }

 20% {
  background-color: transparent;
 }

 30% {
  background-color: black;
 }

 40% {
  background-color: transparent;
 }

 50% {
  background-color: black;
 }

 60% {
  background-color: transparent;
 }

 70% {
  background-color: black;
 }

 80% {
  background-color: transparent;
 }

 90% {
  background-color: black;
 }

 100% {
  background-color: transparent;
 }
 `

export const Wrapper = styled.div<{ disable: boolean, loading: boolean, selected?: boolean, runNewAnimation?: boolean, }>`
 background: ${({ theme, }) => theme.palette.common.white};
 pointer-events: ${({ disable, loading, }) => ((disable || loading) ? "none" : "auto")};
 opacity: ${({ loading, disable, }) => ((loading || disable) ? 0.4 : 1)};
 border: 1px solid ${({ theme, }) => theme.palette.common.grey2};
 padding: 1px;
 border-radius: 22px;
 margin-top: 8px;
 display:flex ;
 justify-content: space-between;
 box-shadow: 0px 23px 33px -24px rgba(0, 0, 0, 0.25);
 user-select: none;
 position: relative;

 &:last-child {
  margin-bottom: 8px;
 }

 &:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 22px;
  background-color: transparent;
  animation: ${({ runNewAnimation, }) => (runNewAnimation ? css`${newOrderKeyframe} 2s 1 forwards` : "")};
 }
 
 ${({ selected, theme, }) => {
    if (selected) {
      return `
      & {
        border-width: 2px;
        padding: 0px;
        border-color: ${theme.secondary.main};
      }
      `
    }
    return ""
  }}
 `
export const ContentWrapper = styled.div`
 padding: 0 16px;
 justify-content:space-between;
 display: flex;
 align-items: center;
 flex: 1;
 `
export const TitleWrapper = styled.div`
  padding: 14px 0;
  flex: 1;
  display: flex;
  flex-direction:column;
  border-right: 1px solid ${({ theme, }) => theme.palette.common.grey2};
  width: 50%;
`
export const Title = styled.span`
  text-align: center;
  font-size:20px;
  font-weight: 600;
  color:${({ theme, }) => theme.palette.common.dark};
 `
export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme, }) => theme.disabled.main};
  text-align: center;
 `
export const Time = styled.span<{ state: OrderState, }>`
 font-size:20px;
 border-radius:8px;
 font-weight: 600;
 color:${({ theme, }) => theme.primary.main};
 `
export const TimeWrapper = styled.div<{ state: OrderState, }>`
  padding: 14px 0;
  display:flex;
  flex-direction:column;
  font-size: 14px;
  align-items:center;
  font-weight:500;
  color: ${({ theme, }) => theme.primary.main};
  padding: 0 23px;
  width: 50%;
  `
export const RightButton = styled.div<{ state: OrderState, }>`
  display:flex;
  width:30px;
  min-height: 100%;
  border-top-right-radius:20px;
  border-bottom-right-radius:20px;
  justify-content:center;
  background-color: ${({ theme, }) => theme.primary.main};
    
  img{
    
  ${({ state, }) => (state === OrderState.onHold
    ? css` width:8px; `
    : css` width:13px; `)}
  }
  `