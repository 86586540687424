import React, { useEffect, useState } from "react"
import {

  Time,
  TimeWrapper,
  Title,
  Wrapper,
  Subtitle,
  TitleWrapper,
  ContentWrapper,
  RightButton
} from "./styles/OrderStyles"
import arrowRight from "assets/icons/arrow_right.svg"
import search from "assets/icons/search.svg"
import { OrderState } from "gdu-vq-common/src/api/interfaces/shared/models/Order"
import { calculateDiff } from "util/CalculateTimeDiff"



type Props = {
  alternativeId: string,
  estimatedDate: string,
  state: OrderState,
  disable: boolean,
  loading: boolean,
  handleClick: () => void,
  selected?: boolean,
  runNewAnimation?: boolean,
}


const Order = ({
  alternativeId, estimatedDate, state, disable, loading, handleClick, selected, runNewAnimation,
}: Props) => {
  const [estimatedTime, setEstimatedTime,] = useState(calculateDiff(estimatedDate))

  useEffect(() => {
    const interval = setInterval(() => {
      setEstimatedTime(calculateDiff(estimatedDate))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Wrapper
      disable={disable}
      loading={loading}
      onClick={handleClick}
      selected={selected}
      runNewAnimation={runNewAnimation}
    >
      <ContentWrapper>
        <TitleWrapper>
          <Subtitle>Código identificador</Subtitle>
          <Title>{alternativeId}</Title>
        </TitleWrapper>
        <TimeWrapper state={state}>
          <Time state={state}>
            {estimatedTime}
          </Time>
          Min de espera
        </TimeWrapper>
      </ContentWrapper>
      <RightButton state={state} >
        {state === OrderState.onHold
          ? <img src={arrowRight} alt="arrow-right" />
          : <img src={search} alt="search" />}
      </RightButton>
    </Wrapper>
  )
}

export default Order
