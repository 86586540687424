import React, { useEffect, useState } from "react"
import { calculateDiff } from "util/CalculateTimeDiff"
import {
    TimeP,
    TextP,
    StyledDiv
} from "./styles/PreparationTimeStyle"

export const PreparationTime = (props: {
    time: string | null,
    text: string,
}) => {
    const [estimatedTime, setEstimatedTime,] = useState(calculateDiff(props.time))

    useEffect(() => {
        const interval = setInterval(() => {
            setEstimatedTime(calculateDiff(props.time))
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return (
        <StyledDiv disable={!estimatedTime}>
            <TimeP disable={!estimatedTime}>
                {estimatedTime
                    ? `${estimatedTime} MIN`
                    : "- -"
                }
            </TimeP>
            <TextP disable={!estimatedTime}>{props.text}</TextP>
        </StyledDiv>
    )
}