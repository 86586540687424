import { Brands } from "gdu-vq-common/src/api/interfaces/shared/models/Brand"
import { generatePath, matchPath, Params, useParams } from "react-router-dom"

export const initialPath = "/:brand/*"
const basePath = "/:brand"

export const pathsDefinition = {
    root: `${basePath}`,
    login: `${basePath}/login`,
    orders: `${basePath}/orders`,
    logout: `${basePath}/logout`,
    notFound: `${basePath}/*`,
} as const

const generatePaths = (params: Params) =>
    (key: keyof typeof pathsDefinition, extraParams?: Params) =>
        generatePath(pathsDefinition[key], { ...params, ...extraParams, })

export const usePathsNavigator = () => {
    const urlParams = useParams()
    return generatePaths(urlParams)
}

export const getBrand = () =>
    matchPath(`${pathsDefinition.root}/*`, window.location.pathname)!.params

export const useBrand = () => {
    const urlParams = useParams<"brand">()
    return urlParams.brand as Brands
}