import styled from "styled-components"
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 10px;
  min-height: auto;
  background-color: ${({ theme, }) => theme.palette.common.red2};
  border-radius: 8px;
  font-size: 11px;
  align-items: center;
  color: ${({ theme, }) => theme.palette.common.red};
`

export const Img = styled.img`
  margin-right: 8px;
`